import React from "react"

import { Container, Row, Col, Modal } from "react-bootstrap"

// import { ImageOverlay, MapContainer, TileLayer, useMap } from "react-leaflet";
// import L from "leaflet"

import { Fade } from "react-reveal"

import asfpv2 from "../img/asfpv2.png"
import chasv2 from "../img/chasv2.png"
import firasv3 from "../img/firasv3.png"
import smasv2 from "../img/smasv2.png"
import iso9001v2 from "../img/iso9001v2.png"
import constructionline from "../img/constructionline.png"

export default class Accreditations extends React.Component {
	constructor(props) {
		super(props)
		this.show = this.show.bind(this)
		this.hide = this.hide.bind(this)
		this.state = {
			accreditations: [
				{
					name: "ASFP",
					description: "Fireless Ltd joined the ASFP as a contractor member in 2020; as the UK's leading trade association relating to 'Built-In' fire protection, the ASFP is dedicated to the protection of life, property, the environment and our heritage and promotes continuous improvement in all aspects of passive fire protection.<br/><br/>The ASFP strives to promote excellence in the design and installation of fire protection products through high quality and technical expertise, by fostering the education and training of all those involved in the development, specification and use of 'Built-In' passive fire protection.<br/><br/>The ASFP only recommends third party product certifications schemes that have been accredited by UKAS.",
					icon: asfpv2,
					show: false
				},
				{
					name: "CHAS",
					description: "</p><div class='text-center'><a href='https://www.chas.co.uk' target='_blank'>www.chas.co.uk</a></div><p class='text-left'><br/>For the ninth year in a row Fireless Ltd have achieved the CHAS standard for Health & Safety!<br/><br/>CHAS helps clients (buyers) and contractors (suppliers) ensure compliance across the different areas of risk management, and mitigate risks across the supply chain.<br/><br/>They help businesses achieve compliance, mitigate supply chain risks and support efficient supply chain management.<br/><br/>CHAS is committed to setting health and safety benchmarks that drive industries forward and make the world of work a safer place.",
					icon: chasv2,
					show: false
				},
				{
					name: "FIRAS",
					description: "Fireless Ltd joined the FIRAS certification scheme for the application of passive fire resisting products using Penetration Sealing Systems and Cavity Barriers in 2017. In 2023, we added the installation of Timer Doors to our scope.<br/><br/>FIRAS certification started in 1994 and has been instrumental in improving standards of installation of fire protection products/systems to the benefit of the construction industry and the clients it serves.<br/><br/>FIRAS is a third party certification for installation contractors of both passive and active fire protection systems, operated by Warrington Certification, and accredited by UKAS to EN45011.",
					icon: firasv3,
					show: false
				},
				{
					name: "ISO 9001",
					description: "Fireless Ltd have held our ISO 9001 Quality Management since 2018; having ISO 9001 has helped our business to improve efficiency, win and retain business, achieve operational excellence and stand out from our competitors.<br/><br/>British Assessment Bureau holds accreditation with UKAS, the only government-backed body for ISO certification in the United Kingdom.<br/><br/>Accreditation determines the competence and integrity of organisations offering testing and certification services.",
					icon: iso9001v2,
					show: false
				},
				{
					name: "SMAS Worksafe",
					description: "For the fifth year running Fireless Ltd has been assessed against the SSiP core criteria and met the standard in order to achieve Contractor status.<br/><br/>SMAS Worksafe is a leading H&S assessment provider and SSIP registered Member Scheme.<br/><br/>The Scheme assesses thousands of contractors annually according to the SSIP core criteria and are audited on a yearly basis to ensure a consistent standard is being delivered.<br/><br/>SMAS Worksafe works in collaboration with key trade associations and other partners in Construction and its certificate is recognised by thousands of individual clients across the UK.",
					icon: smasv2,
					show: false
				},
				{
					name: "Construction Line Gold",
					description: "Fireless Ltd have successfully renewed our Constructionline Gold Membership for the second year in a row. This allows us to put our business before the UK'nps largest network of construction buyers and give them all the information they need to select us for their next project.<br/><br/>Constructionline Gold, one of the most premium membership levels, illustrates to buyers that our organisation has been subjected to increased scrutiny around governance and risk management. Ultimately, it enables us to bid for and win more work.",
					icon: constructionline,
					show: false
				}
			]
		}
	}

	show(e, i) {
		e.preventDefault()
		var accreditations = this.state.accreditations
		accreditations[i].show = true
		this.setState({
			accreditations: accreditations
		})
	}

	hide(i) {
		var accreditations = this.state.accreditations
		accreditations[i].show = false
		this.setState({
			accreditations: accreditations
		})
	}

	render() {

		return (
			<>
				{ this.state.accreditations.map((v, i) => {
					return(
						<Modal close centered show={v.show} onHide={() => {this.hide(i)}}>
							<Modal.Header className="text-right">
								<Row className="no-gutters w-100">
									<Col/>
									<Col xs="auto">
										<i className="mb-0 fas fa-times" style={{ cursor: "pointer" }} onClick={() => {this.hide(i)}}/>
									</Col>
								</Row>
							</Modal.Header>
							<Modal.Body className="text-center">
								<Row className="no-gutters">
									<Col xs="5" className="mx-auto">
										<img src={v.icon} title={v.name} alt={v.name} className="mb-3 w-100"/>
									</Col>
								</Row>
								{/* <h1>{v.name}</h1> */}
								<p className="text-left" dangerouslySetInnerHTML={{ __html: v.description }}></p>
							</Modal.Body>
						</Modal>
					)
				})}
				<div className="bg-secondary">
					<Container className="py-5">
						<Row>
							{ this.state.accreditations.map((v, i) => {
								return(
									<Col xs="4" md="3" lg="2" className="m-auto">
										<Fade left delay={i*250}>
											<div>
												<div className="position-absolute">
													<div className="position-relative" style={{ top: 25, left: 25, transform: "translateY(-50%) translateX(-50%)", cursor: "pointer" }} onClick={(e) => {this.show(e, i)}}>
														<i className="h4 fas fa-info-circle text-dark"/>
													</div>
												</div>
												<img src={v.icon} title={v.name} alt={v.name} className="w-100 p-4" style={{ cursor: "pointer" }} onClick={(e) => {this.show(e, i)}}/>
											</div>
										</Fade>
									</Col>
								)
							})}
						</Row>
					</Container>
				</div>
			</>
		)
	}
}